body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto Condensed', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*Scrollbar */
body::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;

}

body::-webkit-scrollbar-thumb {
  background-color: darkgray;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */

}
