.imageButtons{
    height: 37px;
    margin-right: 10px;
}

.login-card{
    background-color: black !important;
    opacity: 0.5; 
    margin-top: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    width: 350px;
    color: #fff !important;
}
.MenuItem{
    background-color: #fff;
}

.login-card-text{
    margin-top: '20px';

}

.textfield-box{
    color: #fff !important;
    font-weight: 500;
    border-color: #fff;
}


